import React from 'react'

const Privacycomp = () => {
  return (
    <div>
        <div>
  <div className="terms-wrap ptb-100">
    <div className="container">
      <div className="row gx-5">
        <div className="col-lg-10 offset-lg-1">
          <div className="service-desc">
            <div className="single-terms">
              <h2>Who We Are</h2>
              <p>Many desktop publishing packages and web page editose Lorem Ipsum as default model text,
                and a search for'lorem ipsum' will uncover many web sites still in their Various
                versions have evolved over the years, sometimes by accident, sometimes on purpose
                (injected humour and the like).</p>
              <p>There are many variations of passages of Lorem Ipsum available, but they have suffered
                alteration in some forminjected humour, or randomised words which don't look even
                slightly believable.</p>
              <p>If you are going to use a passage of Lorem Ipsum, you need to be sure tn't anything
                embarrg hidden in the middle of text. All the Lorem Ipsum generators on the Internet
                tepeat predefined chunks as neces, making this the first generator on the Internet. It
                uses a dictionary of over 200 Latin words, combined with.</p>
            </div>
            <div className="single-terms">
              <h2>Comments</h2>
              <p>It uses a dictionary of over 200 Latin words, combined with a handful of model sentence
                structu generate Loremwhich looks reasonable. The generated Lorem Ipsum is therefore
                always free from repetition, injected humour,
                or non-characteristic words etc.</p>
              <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a
                piece of classical Latin making it over 2000 years old. Richard McClintock, a Latin
                professor at Hampden-Sydney College ininia, looked
                the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through
                of the word in literature, discovered the undoubtable source.</p>
            </div>
            <div className="single-terms">
              <h2>Media</h2>
              <p>Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus BonMalorum" (The
                Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise onheory
                of ethics, very popular during the Renaissance. The
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a lineion 1.10.32.</p>
              <p>The standard chunk of Lorem Ipsum used since th is reproduced below for those interested.
                Sections 1.10.32 from "de Finibus Bonorum et Malorum" byo are also reproduced their
                exact original form, accompanied by English
                versions from the 1914 translation by H. Rackham.</p>
            </div>
            <div className="single-terms">
              <h2>Cookies</h2>
              <p>It is a long established fact that a reader will be died by the readable content of a
                page when looking at its layout point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using content here', making it look like
                readable English.</p>
              <p>Many desktop publishing packages and web page ors now use Lorem ipsum dolor sit amet
                Lorem Ipsum as their default accident, sometimes on purpose (injected humour and the
                like).</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

    </div>
  )
}

export default Privacycomp