import React from 'react'

const HomeBlog = () => {
  return (
    <div>
        <div className="container-fluid pb-75">
                    <div className="news-col-wrap">
                        <div className="news-col-one">
                            <div className="news-card-two">
                                <div className="news-card-img">
                                    <img src="../assets/img/news/news-1.webp" alt="Image" />
                                    <a href="business.html" className="news-cat">Politics</a>
                                </div>
                                <div className="news-card-info">
                                    <h3><a href="business-details.html">What The Federal Infrastructure Package Means For
                                        Minnesota</a></h3>
                                    <ul className="news-metainfo list-style">
                                        <li><i className="fi fi-rr-calendar-minus" /><a href="news-by-date.html">Apr 25, 2024</a></li>
                                        <li><i className="fi fi-rr-clock-three" />10 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="news-card-three">
                                <div className="news-card-img">
                                    <img src="assets/img/news/news-3.webp" alt="Image" />
                                </div>
                                <div className="news-card-info">
                                    <a href="business.html" className="news-cat">Fashion</a>
                                    <h3><a href="business-details.html">How To Recreate The High Pony-tail That Celebrities Love</a>
                                    </h3>
                                    <ul className="news-metainfo list-style">
                                        <li><i className="fi fi-rr-calendar-minus" /><a href="news-by-date.html">Apr 15, 2024</a></li>
                                        <li><i className="fi fi-rr-clock-three" />11 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="news-card-three">
                                <div className="news-card-img">
                                    <img src="assets/img/news/news-4.webp" alt="Image" />
                                </div>
                                <div className="news-card-info">
                                    <a href="business.html" className="news-cat">Fashion</a>
                                    <h3><a href="business-details.html">Tempores Imperdiet Rhoncus Ipsam Lobortis Kolats.</a></h3>
                                    <ul className="news-metainfo list-style">
                                        <li><i className="fi fi-rr-calendar-minus" /><a href="news-by-date.html">Apr 14, 2024</a></li>
                                        <li><i className="fi fi-rr-clock-three" />10 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="news-card-three">
                                <div className="news-card-img">
                                    <img src="assets/img/news/news-5.webp" alt="Image" />
                                </div>
                                <div className="news-card-info">
                                    <a href="business.html" className="news-cat">Fashion</a>
                                    <h3><a href="business-details.html">Beauty Queens Need Beauty Material &amp; Products</a></h3>
                                    <ul className="news-metainfo list-style">
                                        <li><i className="fi fi-rr-calendar-minus" /><a href="news-by-date.html">Apr 10, 2024</a></li>
                                        <li><i className="fi fi-rr-clock-three" />8 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="news-col-two">
                            <div className="news-card-four">
                                <img src="assets/img/news/news-58.webp" alt="Image" />
                                <div className="news-card-info">
                                    <h3><a href="business-details.html">Best VR Headsets For PC And Gaming This Year</a></h3>
                                    <ul className="news-metainfo list-style">
                                        <li><i className="fi fi-rr-calendar-minus" /><a href="news-by-date.html">Apr 08, 2024</a></li>
                                        <li><i className="fi fi-rr-clock-three" />14 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="news-card-five">
                                <div className="news-card-img">
                                    <img src="assets/img/news/news-59.webp" alt="Image" />
                                    <a href="business.html" className="news-cat">Fashion</a>
                                </div>
                                <div className="news-card-info">
                                    <h3><a href="business-details.html">Man Wearing Black Pullover Hoodie To Smoke Light In</a></h3>
                                    <p>Lorem ipsum or lipsum as it is sometmes known is dum text used in laying print, graphic or
                                        web desi…</p>
                                    <ul className="news-metainfo list-style">
                                        <li><i className="fi fi-rr-calendar-minus" /><a href="news-by-date.html">Apr 08, 2024</a></li>
                                        <li><i className="fi fi-rr-clock-three" />10 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="news-card-five">
                                <div className="news-card-img">
                                    <img src="assets/img/news/news-60.webp" alt="Image" />
                                    <a href="business.html" className="news-cat">Travel</a>
                                </div>
                                <div className="news-card-info">
                                    <h3><a href="business-details.html">Selective Focus Photography Of Orange Fox Life</a></h3>
                                    <p>Lorem ipsum or lipsum as it is sometmes known is dum text used in laying print, graphic or
                                        web desi…</p>
                                    <ul className="news-metainfo list-style">
                                        <li><i className="fi fi-rr-calendar-minus" /><a href="news-by-date.html">Apr 03, 2024</a></li>
                                        <li><i className="fi fi-rr-clock-three" />11 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="news-col-three">
                            <div className="news-card-two">
                                <div className="news-card-img">
                                    <img src="assets/img/news/news-2.webp" alt="Image" />
                                    <a href="business.html" className="news-cat">Politics</a>
                                </div>
                                <div className="news-card-info">
                                    <h3><a href="business-details.html">Elijah James: The Nashville Photographer Shares Her Unique
                                        Journey</a></h3>
                                    <ul className="news-metainfo list-style">
                                        <li><i className="fi fi-rr-calendar-minus" /><a href="news-by-date.html">Apr 03, 2024</a></li>
                                        <li><i className="fi fi-rr-clock-three" />12 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="news-card-three">
                                <div className="news-card-img">
                                    <img src="assets/img/news/news-7.webp" alt="Image" />
                                </div>
                                <div className="news-card-info">
                                    <a href="business.html" className="news-cat">Travel</a>
                                    <h3><a href="business-details.html">A Complimentary Day At Mandarin The Oriental</a></h3>
                                    <ul className="news-metainfo list-style">
                                        <li><i className="fi fi-rr-calendar-minus" /><a href="news-by-date.html">Mar 15, 2024</a></li>
                                        <li><i className="fi fi-rr-clock-three" />10 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="news-card-three">
                                <div className="news-card-img">
                                    <img src="assets/img/news/news-6.webp" alt="Image" />
                                </div>
                                <div className="news-card-info">
                                    <a href="business.html" className="news-cat">Business</a>
                                    <h3><a href="business-details.html">First Prototype Flight Using Kinetic Launch System</a></h3>
                                    <ul className="news-metainfo list-style">
                                        <li><i className="fi fi-rr-calendar-minus" /><a href="news-by-date.html">Feb 22, 2024</a></li>
                                        <li><i className="fi fi-rr-clock-three" />8 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="news-card-three">
                                <div className="news-card-img">
                                    <img src="assets/img/news/news-8.webp" alt="Image" />
                                </div>
                                <div className="news-card-info">
                                    <a href="business.html" className="news-cat">Health</a>
                                    <h3><a href="business-details.html">Life Health Continues To Spread Rapidly, Are Many People</a>
                                    </h3>
                                    <ul className="news-metainfo list-style">
                                        <li><i className="fi fi-rr-calendar-minus" /><a href="news-by-date.html">Feb 15, 2024</a></li>
                                        <li><i className="fi fi-rr-clock-three" />10 Min Read</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
  )
}

export default HomeBlog